import { MultiButtons } from '@/gf/components/MultiButton'
import { completesEditQuote, initiatesEditQuote } from '@/gf/modules/GrammarEvents'
import { cleanGraphQlInput } from '@/gf/modules/GraphQL'
import MoneyM from '@/gf/modules/Money'
import TimeM from '@/gf/modules/Time'
import MachineInformation from '../MachineInformation'
import QuoteBuilder from '../QuoteBuilder'
import { QuoteUploadProps } from '../QuoteUpload/QuoteUploadButton'
import StepNote from '../StepNote'
import { viewOriginalRequestAction } from '../ViewOriginalRequest'
import { useRfqContext } from '../context'
import QuoteUploadBar from '../QuoteUpload/QuoteUploadBar'

const EditQuote = ({
  quoteUpload,
  exitEditMode,
  onCreateShipping,
  onReadyForPickup,
  onAcceptQuote,
  ...props
}: {
  quoteUpload: Pick<QuoteUploadProps, 'onError' | 'onUploadComplete' | 'onUploadStarted'>
  onQuoteSuccess: () => Promise<void>
  exitEditMode: () => void
  onCreateShipping: () => void
  onReadyForPickup: () => void
  onAcceptQuote: () => void
}) => {
  const { rfq, invoiceSent } = useRfqContext()

  if (!rfq.quote) return null

  const initialValues = {
    customerContactId: null,
    shippingCost: rfq.quote.shippingAmount ? MoneyM.toDecimal(rfq.quote.shippingAmount) : null,
    taxCost: MoneyM.toDecimal(rfq.quote.salesTax),
    customerDiscount: rfq.quote.discounts.reduce((sum, { percent }) => sum + percent, 0),
    quoteNumber: rfq.quote.quoteNumber,
    deliveryMethod: rfq.quote.deliveryMethod,
    items: rfq.quote.lineItems.map((li) => ({
      id: li.id,
      partNumber: li.product.mpn,
      description: li.product.name,
      unitPrice: MoneyM.toDecimal(li.unitPrice),
      quantity: li.quantity,
      inStock: li.inStock ?? true,
      externalId: li.externalId,
      taskNumber: li.taskNumber,
      suggestion: li.suggestion,
      availabilityDate: li.availableAt ? TimeM.toPayload(li.availableAt) : null,
      rfqPartId: li.rfqPart?.id ?? null,
      rfqPartNumber: li.rfqPart?.mpn ?? null,
    })),
    pickupAddress: rfq.quote.pickupAddress ? cleanGraphQlInput(rfq.quote.pickupAddress) : null,
    shippingAddress: rfq.quote.shippingAddress
      ? cleanGraphQlInput(rfq.quote.shippingAddress)
      : null,
    comments: null,
    neededByDate: null,
    urgency: null,
  }

  const onQuoteSuccess = (storeOrderId: string) => {
    props.onQuoteSuccess()
    completesEditQuote({ rfqId: rfq.id, storeOrderId })
  }

  return (
    <QuoteBuilder
      storeOrderId={rfq.quote.id}
      initialValues={initialValues}
      images={rfq.images.map((i) => i.url)}
      machineDetailsSection={
        <>
          <MachineInformation reqMachine={rfq.machines[0] ?? null} className="px-4" />

          {rfq.partsRequest && (
            <p className="px-4 leading-5 text-sm prose whitespace-pre-wrap">
              <span className="text-gray-900 font-medium">Comments from the customer:</span>{' '}
              {rfq.partsRequest}
            </p>
          )}
        </>
      }
      onSuccess={onQuoteSuccess}
      onCancel={exitEditMode}
      actions={
        <QuoteUploadBar
          onError={quoteUpload.onError}
          onUploadComplete={quoteUpload.onUploadComplete}
          onUploadStarted={quoteUpload.onUploadStarted}
          rfqId={rfq.id}
        />
      }
      sideNote={
        <StepNote
          rfq={rfq}
          onCreateShipping={onCreateShipping}
          onReadyForPickup={onReadyForPickup}
          invoiceSent={invoiceSent}
          onAcceptQuote={onAcceptQuote}
        />
      }
      secondaryActions={
        <MultiButtons className="!shadow-sm" items={[viewOriginalRequestAction(rfq)]} />
      }
      showSalesTax={rfq.requestDetails !== null}
      customerFeeRate={rfq.quote.customerFeeRate}
      canSetDeliveryMethod={!!rfq.requestDetails}
      onTouched={() => initiatesEditQuote({ rfqId: rfq.id, storeOrderId: rfq.quote?.id })}
      showWhenCustomerDiscountAutoFilled={false}
    />
  )
}

export default EditQuote
