import {
  DeliveryMethod,
  PaymentMethod,
  RequestForQuoteStep,
  StoreOrderState,
} from '@/dealers/_gen/gql'
import MultiButton, { MultiButtons } from '@/gf/components/MultiButton'
import useConfig from '@/gf/hooks/useConfig'
import useToggle from '@/gf/hooks/useToggle'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { DownloadIcon, XIcon } from '@heroicons/react/outline'
import { ReactNode, useMemo } from 'react'
import ContactCustomer from './ContactCustomer'
import CancelOrderModal from './Details/CancelOrderModal'
import Problem from './Details/Problem'
import ApprovePartialQuote from './Details/ViewQuote/ApprovePartialQuote'
import ProofOfDeliveries from './ProofOfDeliveries'
import StepNote from './StepNote'
import { viewOriginalRequestAction } from './ViewOriginalRequest'
import { useRfqContext } from './context'
import useModal from './useModal'

const SidebarLayout = ({ children, className }: { children: ReactNode; className?: string }) => {
  const { rfq, invoiceSent } = useRfqContext()
  const { dealersUrl } = useConfig()
  const { onReadyForPickup, onCreateShipping, onAcceptQuote } = useModal()
  const [cancelOrderModalOpen, cancelOrderModalToggler] = useToggle()

  const invoice = rfq.quote?.invoices[0]

  const secondaryActions = useMemo(() => {
    const result: Parameters<typeof MultiButton>[0]['actions'] = []

    if (rfq.quote) {
      if (rfq.step === RequestForQuoteStep.Quoted)
        result.push({
          key: 'download-quote',
          display: 'Download Quote',
          icon: DownloadIcon,
          to: `/pdf/download/quote/${rfq.quote.id}`,
          target: '_blank',
        })

      if (
        [
          RequestForQuoteStep.PoReceived,
          RequestForQuoteStep.Fulfilling,
          RequestForQuoteStep.Fulfilled,
        ].includes(rfq.step)
      )
        result.push({
          key: 'download-po',
          display: 'Download PO',
          icon: DownloadIcon,
          to: `/pdf/download/po/${rfq.quote.id}`,
          target: '_blank',
        })

      if (
        (rfq.quote?.deliveryMethod ?? rfq.requestDetails?.deliveryMethod) ===
          DeliveryMethod.Shipping &&
        StoreOrderM.isQuoteApproved(rfq.quote)
      )
        result.push({
          key: 'download-packing-slip',
          display: 'Download Packing Slip',
          icon: DownloadIcon,
          to: `/pdf/download/packing_slip/${rfq.quote.id}`,
          target: '_blank',
        })

      const commonInvoiceAttrs = {
        key: 'download-invoice',
        display: 'Download Invoice',
        icon: DownloadIcon,
        target: '_blank',
      }

      if (rfq.quote.paymentMethod === PaymentMethod.Direct) {
        if (invoice?.link) {
          result.push({
            ...commonInvoiceAttrs,
            to: invoice.link,
          })
        }
      } else {
        if (invoice) {
          result.push({
            ...commonInvoiceAttrs,
            to: `${dealersUrl}/pdf/download/invoice/${rfq.quote.invoices[0].id}`,
          })
        }
      }
    }

    result.push(viewOriginalRequestAction(rfq))

    return result
  }, [rfq])

  const secondaryMutations = useMemo(() => {
    const result: Parameters<typeof MultiButton>[0]['actions'] = []
    if (rfq.quote) {
      const showCancelOrderAction =
        rfq.quote.paymentMethod === PaymentMethod.Direct &&
        rfq.quote.state === StoreOrderState.Received
      if (showCancelOrderAction) {
        result.push({
          key: 'cancel-order',
          display: 'Cancel Order',
          icon: XIcon,
          onClick: cancelOrderModalToggler.on,
        })
      }
    }
    return result
  }, [rfq])

  const rejectedLineItems = useMemo(
    () => rfq.quote?.lineItems.filter((li) => !!li.rejectedAt) ?? [],
    [rfq.quote]
  )

  return (
    <div className="flex flex-col sm:flex-row gap-x-6">
      <div className={className}>{children}</div>

      {/* SIDEBAR */}
      <div className="relative">
        <div className="w-80 max-w-[20.375rem] flex flex-col gap-y-4 sticky top-4 lg:top-16">
          {rfq.quote && (
            <CancelOrderModal
              open={cancelOrderModalOpen}
              onClose={cancelOrderModalToggler.off}
              storeOrderId={rfq.quote.id}
              rfqId={rfq.id}
            />
          )}

          <StepNote
            rfq={rfq}
            onCreateShipping={onCreateShipping}
            onReadyForPickup={onReadyForPickup}
            onAcceptQuote={onAcceptQuote}
            invoiceSent={invoiceSent}
          />

          {rfq.quickOrder && <ContactCustomer />}

          {rfq.quote &&
            rfq.quote.problems
              .filter(StoreOrderM.isStoreOrderProblemOpen)
              .map((p) => <Problem rfqId={rfq.id} problem={p} />)}

          {secondaryMutations.length > 0 && (
            <MultiButtons className="!shadow-sm" items={secondaryMutations} />
          )}

          {secondaryActions.length > 0 && (
            <MultiButtons className="!shadow-sm" items={secondaryActions} />
          )}

          {rejectedLineItems.length > 0 && rfq.quote && (
            <ApprovePartialQuote rfq={{ ...rfq, quote: rfq.quote }} />
          )}

          <ProofOfDeliveries />
        </div>
      </div>
    </div>
  )
}

export default SidebarLayout
