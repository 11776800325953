import useToggle from '@/gf/hooks/useToggle'
import StartTrialModal from './StartTrialModal'
import A from '@/gf/components/A'
import SiteUrls from '@/retail/modules/SiteUrls'

const ActionButtons = () => {
  const [trialModalOpen, trialModalToggle] = useToggle()

  return (
    <>
      <StartTrialModal open={trialModalOpen} onClose={trialModalToggle.off} />

      <div className="flex gap-x-4 flex-shrink-0">
        <A.S
          href={SiteUrls.DEALER_SCHEDULE_DEMO_URL}
          target="_blank"
          size="lg"
          className="w-1/2 font-medium text-blue-500 flex items-center justify-center flex-shrink-0"
        >
          Schedule a Demo
        </A.S>
        <A.P
          href={SiteUrls.DEALER_SCHEDULE_DEMO_URL}
          target="_blank"
          size="lg"
          className="w-1/2 font-medium flex items-center justify-center flex-shrink-0"
          color="blue"
        >
          Get Pricing
        </A.P>
      </div>
    </>
  )
}

export default ActionButtons
