import AccountMachine from '@/gf/modules/AccountMachine'
import AdditionalCharge from '@/gf/modules/AdditionalCharge'
import DiscountM from '@/gf/modules/Discount'
import GraphQL from '@/gf/modules/GraphQL'
import Invoice from '@/gf/modules/Invoice'
import LineItem from '@/gf/modules/LineItem'
import Money from '@/gf/modules/Money'
import Refund from '@/gf/modules/Refund'
import GfStoreOrder from '@/gf/modules/StoreOrder'
import StoreOrderEvent from '@/gf/modules/StoreOrderEvent'
import Time from '@/gf/modules/Time'
import Vendor from '@/gf/modules/Vendor'
import hex from 'crypto-js/enc-hex'
import sha1 from 'crypto-js/sha1'
import groupBy from 'lodash/groupBy'
import {
  CreateOrderFormCustomer,
  CustomerInput,
  EditLineItem,
  ShippingCost,
  Store,
} from '../../types'
import { Discount, NewStoreOrder, StoreOrder, StoreOrderPayload } from '../types'
import Order from './StoreOrder/Order'

const fromPayload = (payload: StoreOrderPayload): StoreOrder => ({
  ...payload,
  order: Order.fromPayload(payload.order),
  shippingAmount: payload.shippingAmount && Money.fromPayload(payload.shippingAmount),
  salesTax: payload.salesTax && Money.fromPayload(payload.salesTax),
  lineItems: payload.lineItems && payload.lineItems.map(LineItem.fromPayload),
  additionalCharges:
    payload.additionalCharges && payload.additionalCharges.map(AdditionalCharge.fromPayload),
  refunds: payload.refunds && payload.refunds.map(Refund.fromPayload),
  discounts: payload.discounts && payload.discounts.map(DiscountM.fromPayload),
  events: payload.events && payload.events.map(StoreOrderEvent.fromPayload),
  insertedAt: Time.fromPayload(payload.insertedAt),
  updatedAt: Time.fromPayload(payload.updatedAt),
  accountMachines:
    payload.accountMachines &&
    payload.accountMachines.map((accountMachinePayload) =>
      AccountMachine.fromPayload(accountMachinePayload)
    ),
  vendor: payload.vendor ? Vendor.fromPayload(payload.vendor) : undefined,
  invoices: payload.invoices
    ? payload.invoices.map((invoice) => Invoice.fromPayload(invoice))
    : undefined,
  total: Money.fromPayload(payload.total),
})

// Returns the customer id associated with the given storeOrder
const customerId = (storeOrder: Omit<StoreOrder, 'payOnInvoice'>) =>
  storeOrder.order.customer.user?.id ?? sha1(storeOrder.order.customer.email).toString(hex)

const editLineItemsToNewStoreOrders = (
  newOrderLineItems: EditLineItem[],
  allStores: Store[],
  shippingCosts: ShippingCost[],
  discounts: Discount[] | undefined
) =>
  Object.entries(groupBy(newOrderLineItems, (li) => li.product.store.id)).map(
    ([storeId, lineItems]): NewStoreOrder => {
      const lineItemStore = allStores.find((s) => s.id === storeId)
      const storeShippingCost = shippingCosts.find((s) => s.storeId === storeId)

      const storeDiscount = discounts?.find((d) => d.store.id === storeId) || null

      const shippingCost =
        storeShippingCost && storeShippingCost.shippingCost
          ? Money.fromString(storeShippingCost.shippingCost, 'USD')
          : null

      const subtotal = lineItems.map((li) => li.extendedPrice).reduce(Money.add)

      return {
        store: {
          id: storeId,
          name: (lineItemStore && lineItemStore.name) || 'Items in Order',
          discount: storeDiscount,
        },
        lineItems,
        subtotal,
        shippingCost,
      }
    }
  )

const orderFormCustomerToCustomerInput = (
  orderCustomer: CreateOrderFormCustomer | undefined
): CustomerInput | null =>
  orderCustomer
    ? {
        id: orderCustomer.userId,
        shippingAddress: GraphQL.cleanGraphQlInput(orderCustomer.shippingAddress),
        billingAddress: orderCustomer.billingAddress
          ? GraphQL.cleanGraphQlInput(orderCustomer.billingAddress)
          : null,
        name: orderCustomer.name,
        email: orderCustomer.email,
        phoneNumber: orderCustomer.phoneNumber,
      }
    : null

const getDiscountedUnitPriceDecimal = (
  storeOrder: Pick<StoreOrder, 'discounts'>,
  unitPrice: number
) =>
  storeOrder.discounts
    .map((d) => 1 - d.percent / 100)
    .reduce((acc, multiplier) => acc * multiplier, unitPrice)

export default {
  ...GfStoreOrder,
  fromPayload,
  customerId,
  editLineItemsToNewStoreOrders,
  orderFormCustomerToCustomerInput,
  getDiscountedUnitPriceDecimal,
}
