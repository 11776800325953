import { SearchQuery, useSearchQuery } from '@/dealers/_gen/gql'
import ConversationM from '@/dealers/modules/Conversation'
import * as ProductM from '@/dealers/modules/Product'
import RequestForQuoteM from '@/dealers/modules/RequestForQuote'
import StoreOrderM from '@/dealers/modules/StoreOrder'
import Urls from '@/dealers/modules/Urls'
import Ghost from '@/gf/components/Ghost'
import Id from '@/gf/modules/Id'
import Money from '@/gf/modules/Money'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import type { SearchResults } from './types'
import debounce from 'lodash/debounce'

const Loading = () => (
  <div className="space-y-3">
    {[1, 2, 3].map((i) => (
      <div className="flex flex-col gap-y-1.5" key={i}>
        <Ghost className="h-5 w-72 rounded-full" />
        <Ghost className="h-3 w-3/4" />
      </div>
    ))}
  </div>
)

const conversationName = (conversation: SearchQuery['conversations']['entries'][number]) =>
  conversation.admin
    ? ConversationM.ADMIN_CONVERSATION_NAME
    : conversation.storeOrder
      ? ConversationM.conversationNameForStoreOrder(
          conversation.storeOrder,
          StoreOrderM.canDisplayCustomerContactInfo({
            user: { isCustomerContact: conversation.storeOrder.customer.isCustomerContact },
            state: conversation.storeOrder.state,
          })
        )
      : conversation.requestForQuote
        ? ConversationM.conversationNameForRfq(
            conversation.requestForQuote,
            RequestForQuoteM.canDisplayCustomerContactInfo({
              ...conversation.requestForQuote,
              storeOrder: conversation.requestForQuote.quote ?? null,
            })
          )
        : ''

const mapConversation = (conversation: SearchQuery['conversations']['entries'][number]) => ({
  title: `(${Id.shorten(conversation.id)}) ${conversationName(conversation)}`,
  description: conversation.storeOrder
    ? `Order ${Id.shorten(conversation.storeOrder.id)}`
    : conversation.requestForQuote
      ? `Request ${Id.shorten(conversation.requestForQuote.id)}`
      : '',
  href: Urls.inboxConversation(conversation.id),
})

const mapProduct = (product: SearchQuery['products']['entries'][number]) => ({
  title: `(${Id.shorten(product.id)}) ${product.mpn} - ${product.name}`,
  description: `Sale Price: ${Money.format(Money.fromDecimal(product.salePrice, 'USD'))} - ${
    product.availability ? ProductM.availabilityLabel(product.availability) : ''
  }`,
  href: Urls.product(product.id),
})

const mapCustomer = (customer: SearchQuery['customers']['entries'][number]) => ({
  title: `(${Id.shorten(customer.id)}) ${customer.name}`,
  description: `Account #${customer.accountNumbers.join(', ')}`,
  href: Urls.customerAccount(customer.id),
})

const mapRequest = (request: SearchQuery['paginatedRequestForQuotes']['entries'][number]) => {
  const parts = request.partRequests
    .map((pr) => `${pr.mpn} ${pr.description ?? ''} x ${pr.quantity}`)
    .join(', ')

  const machine = request.machines[0]
  const machineStr = machine
    ? `${machine.details.machine.make ?? ''} ${machine.details.machine.model ?? ''} ${
        machine.details.machine.year ?? ''
      }`
    : ''

  return {
    title: `(${Id.shorten(request.id)}) ${request.user.organization.name ?? ''} · ${
      request.user.displayName ?? ''
    }`,
    description: [machineStr, parts, request.partsRequest ?? ''].filter((a) => !!a).join(' - '),
    href: Urls.requestForQuote(request.id),
  }
}

const SearchResults = ({
  searchTerm,
  onItemSelected,
}: {
  searchTerm: string
  onItemSelected: () => void
}) => {
  const [search, setSearch] = useState(searchTerm)
  const { data, loading } = useSearchQuery({
    variables: { search: search.trim() },
    skip: !search,
    fetchPolicy: 'network-only',
  })

  const debouncedSetSearch = useRef(debounce(setSearch, 400)).current

  useEffect(() => {
    debouncedSetSearch(searchTerm)
  }, [searchTerm])

  const results = useMemo<SearchResults>(() => {
    if (!data || !searchTerm) {
      return { loading: true, entries: [] }
    }

    const customersHits = data.customers.entries.map(mapCustomer)
    const requestsHits = data.paginatedRequestForQuotes.entries.map(mapRequest)
    const conversationsHits = data.conversations.entries.map(mapConversation)
    const productsHits = data.products.entries.map(mapProduct)

    return {
      loading,
      entries: [
        ...(requestsHits.length > 0 ? [{ title: 'Requests', hits: requestsHits }] : []),
        ...(customersHits.length > 0 ? [{ title: 'Customers', hits: customersHits }] : []),
        ...(productsHits.length > 0 ? [{ title: 'Products', hits: productsHits }] : []),
        ...(conversationsHits.length > 0
          ? [{ title: 'Conversations', hits: conversationsHits }]
          : []),
      ],
    } as SearchResults
  }, [searchTerm, data, loading])

  return (
    <>
      {results.loading ? (
        <Loading />
      ) : results.entries.length === 0 ? (
        <p className="p-2 text-sm text-slate-500">
          No results found for <span className="font-medium text-slate-700">{searchTerm}</span>
        </p>
      ) : (
        results.entries.map((result) => (
          <div key={result.title}>
            <h2 className="text-gearflow font-semibold text-xs mt-2 mb-1 pl-2">{result.title}</h2>
            {result.hits.map((hit) => (
              <Link
                key={hit.href}
                to={hit.href}
                className="py-2 pr-2 pl-4 block hover:bg-slate-50 rounded"
                onClick={onItemSelected}
              >
                <span className="font-medium block text-slate-600">{hit.title}</span>
                <span className="text-xs block text-slate-400 mt-0.5">{hit.description}</span>
              </Link>
            ))}
          </div>
        ))
      )}
    </>
  )
}

export default SearchResults
