import {
  DeliveryMethod,
  ProductAvailability,
  SearchCatalogQuery,
  useCreateQuoteMutation,
  useCustomerContactQuery,
  useSearchCatalogLazyQuery,
  useUpdateQuoteMutation,
} from '@/dealers/_gen/gql'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import AddCustomerModal from '@/dealers/components/AddCustomerModal/AddCustomerModal'
import Action from '@/gf/components/Action'
import BadgeNext from '@/gf/components/BadgeNext'
import Box from '@/gf/components/Box'
import ImageGallery from '@/gf/components/ImageGallery'
import InStockCheckbox from '@/gf/components/InStockCheckbox'
import DateInput from '@/gf/components/inputs/Date'
import Percent from '@/gf/components/inputs/Percent'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import Typeahead from '@/gf/components/next/Typeahead'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import TextArea from '@/gf/components/next/forms/TextArea'
import TextInput from '@/gf/components/next/forms/TextInput'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import AddressM from '@/gf/modules/Address'
import * as GE from '@/gf/modules/GrammarEvents'
import MoneyM from '@/gf/modules/Money'
import TimeM from '@/gf/modules/Time'
import debouncePromise from '@/gf/modules/debouncePromise'
import { ApolloError } from '@apollo/client'
import { Address, Maybe } from '@/types'
import { PlusIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pick from 'lodash/pick'
import { DateTime } from 'luxon'
import { HTMLAttributes, useCallback, useEffect, useRef } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import AddressC from './Address'
import AddressModal from './QuoteBuilder/AddressModal'
import CatalogAutoCompleteOption from './QuoteBuilder/CatalogAutoCompleteOption'
import CustomersDropdown from './QuoteBuilder/CustomersDropdown'
import DeliveryMethodSelector from './QuoteBuilder/DeliveryMethodSelector'
import { handleError, OnErrorCallback } from './QuoteBuilder/Errors'
import FieldLabel from './QuoteBuilder/FieldLabel'
import RemovePartButton from './QuoteBuilder/RemovePartButton'
import UrgencySelector from './QuoteBuilder/UrgencySelector'
import useQuoteForm, { deletePersistedForm, FormValues, Urgency } from './QuoteBuilder/useForm'
import useQuoteTotals from './QuoteBuilder/useQuoteTotals'
import useQuoteUpload from './QuoteUpload/useQuoteUpload'
import Summary from './Summary'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const newLineItem = () => ({
  id: null,
  partNumber: '',
  description: '',
  quantity: 1,
  unitPrice: null,
  externalId: null,
  taskNumber: null,
  suggestion: null,
  rfqPartId: null,
  rfqPartNumber: null,
  inStock: true,
  availabilityDate: null,
})

export const addressInput = (address: Maybe<Address>) => ({
  id: null,
  lineOne: address?.lineOne as string,
  lineTwo: address?.lineTwo as string,
  city: address?.city as string,
  state: address?.state as string,
  country: address?.country as string,
  postalCode: address?.postalCode as string,
  companyName: address?.companyName as string,
  deliverable: address?.deliverable ?? null,
  rdi: address?.rdi ?? null,
  firstName: address?.firstName ?? null,
  lastName: address?.lastName ?? null,
  point: address?.point ? pick(address.point, ['lat', 'lng']) : null,
})

const neededByDateFromUrgency = (value: Urgency) => {
  switch (value) {
    case Urgency.HIGH:
      return DateTime.now().plus({ day: 1 })
    case Urgency.MEDIUM:
      return DateTime.now().plus({ days: 3 })
    case Urgency.LOW:
      return DateTime.now().plus({ weeks: 1 })
    default:
      return null
  }
}

export type SearchCatalogResults = SearchCatalogQuery['autocompleteProducts']

interface BaseProps {
  initialValues: FormValues
  machineDetailsSection?: React.ReactNode
  actions?: React.ReactNode
  images: string[]
  onSuccess: (storeOrderId: string) => void
  onCancel?: () => void
  onError?: OnErrorCallback
  sideNote?: React.ReactNode
  secondaryActions?: React.ReactNode
  disabled?: boolean
  showSalesTax?: boolean
  customerFeeRate: number | null
  canSetDeliveryMethod?: boolean
  onTouched?: () => void
  showWhenCustomerDiscountAutoFilled: boolean
}

interface EditQuoteProps extends BaseProps {
  storeOrderId: string
  rfqId?: never
}

interface CreateQuoteForRequest extends BaseProps {
  rfqId: string
  storeOrderId?: never
}

interface CreateQuoteFromScratch extends BaseProps {
  rfqId?: never
  storeOrderId?: never
}

type Props = EditQuoteProps | CreateQuoteForRequest | CreateQuoteFromScratch

const Section = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('py-6', className)} {...props} />
)

const QuoteBuilder = ({
  rfqId,
  storeOrderId,
  initialValues,
  machineDetailsSection,
  actions,
  images,
  onSuccess,
  onCancel,
  onError,
  sideNote,
  secondaryActions,
  disabled = false,
  showSalesTax = true,
  customerFeeRate,
  canSetDeliveryMethod = true,
  onTouched,
  showWhenCustomerDiscountAutoFilled,
}: Props) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [submitting, submitToggle] = useToggle()
  const [addCustomerOpen, addCustomerToggle] = useToggle()
  const [pickupAddressModalOpen, pickupAddressModal] = useToggle()
  const [shippingAddressModalOpen, shippingAddressModal] = useToggle()
  const [createQuote, { loading: creatingQuote }] = useCreateQuoteMutation()
  const [updateQuote, { loading: updatingQuote }] = useUpdateQuoteMutation()
  const [searchCatalog] = useSearchCatalogLazyQuery()
  const quoteUpload = useQuoteUpload()
  const isDirectQuote = !storeOrderId && !rfqId
  const showDeliveryMethod = (isDirectQuote || storeOrderId) && canSetDeliveryMethod
  const urgencyRef = useRef<HTMLDivElement>(null)

  const { control, handleSubmit, register, formState, getValues, setValue, storageUniqueKey } =
    useQuoteForm({ rfqId, storeOrderId, initialValues, onTouched })

  const itemsForm = useFieldArray({ control, name: 'items' })
  const itemsValues = useWatch({ name: 'items', control })
  const taxCost = useWatch({ name: 'taxCost', control })
  const customerDiscount = useWatch({ name: 'customerDiscount', control })
  const deliveryMethod = useWatch({ name: 'deliveryMethod', control })
  const shippingCost = useWatch({ name: 'shippingCost', control })
  const pickupAddress = useWatch({ name: 'pickupAddress', control })
  const shippingAddress = useWatch({ name: 'shippingAddress', control })
  const customerContactId = useWatch({ name: 'customerContactId', control })
  const canRemoveItems = itemsValues.length > 1
  const deliveryMethodAppearsDisabled = !customerContactId && !storeOrderId

  // used for direct quotes
  const { data: customerContactData } = useCustomerContactQuery({
    variables: { customerContactId: customerContactId as string },
    skip: !customerContactId,
    onCompleted(data) {
      setValue('shippingAddress', data.customerContact.account.address, { shouldValidate: true })
    },
  })

  const shippingAmount = shippingCost ? MoneyM.fromDecimal(shippingCost, 'USD') : null
  const taxAmount = taxCost ? MoneyM.fromDecimal(taxCost, 'USD') : zeroPrice

  const { totals } = useQuoteTotals({
    items: itemsValues,
    taxAmount,
    shippingAmount,
    customerDiscount,
    customerFeeRate,
  })

  useEffect(() => {
    if (customerContactId)
      setValue('customerDiscount', customerContactData?.customerContact.account.discount ?? null)
  }, [customerContactData])

  useEffect(() => {
    if (quoteUpload.isReady) setValue('items', quoteUpload.lineItems)
  }, [quoteUpload.isReady])

  const saveQuoteInProgress = creatingQuote || updatingQuote

  const submitQuote = async () => {
    try {
      const values = getValues()

      const variables = {
        taxCost: values.taxCost,
        shippingCost:
          values.deliveryMethod === DeliveryMethod.Shipping ? values.shippingCost : null,
        items: values.items.map((item) => ({
          id: item.id,
          partNumber: item.partNumber,
          description: item.description,
          quantity: item.quantity,
          inStock: item.inStock,
          externalId: item.externalId,
          taskNumber: item.taskNumber,
          suggestion: item.suggestion,
          rfqPartId: item.rfqPartId,
          availabilityDate:
            !item.inStock && item.availabilityDate
              ? TimeM.fromPayload(item.availabilityDate)
              : null,
          unitPrice: item.unitPrice ?? -1,
        })),
        quoteNumber: values.quoteNumber,
        notes: null,
        pickupAddress:
          values.deliveryMethod === DeliveryMethod.Pickup && values.pickupAddress
            ? addressInput(values.pickupAddress)
            : null,
        shippingAddress: addressInput(values.shippingAddress),
        deliveryMethod: values.deliveryMethod,
        discountPercent: customerDiscount,
      }

      if (storeOrderId) {
        const { data } = await updateQuote({ variables: { storeOrderId, ...variables } })
        onSuccess(data?.updateQuote as string)
      } else {
        const { data } = await createQuote({
          variables: {
            rfqId: rfqId as string,
            customerContactId: values.customerContactId,
            requestDetails: isDirectQuote
              ? {
                  comments: values.comments,
                  neededBy: values.neededByDate as DateTime,
                }
              : null,
            ...variables,
          },
        })

        onSuccess(data?.createQuote as string)
      }

      deletePersistedForm(storageUniqueKey)
    } catch (err) {
      const error = err as ApolloError

      if (error?.graphQLErrors?.[0]) {
        msgsMgr.add(error?.graphQLErrors?.[0]?.message, 'negative')
      } else msgsMgr.addUnknownError()

      if (onError) handleError(err, onError)
    } finally {
      submitToggle.off()
      pickupAddressModal.off()
    }
  }

  const onPickupAddressConfirmed = (address: Address) => {
    setValue('pickupAddress', address, { shouldValidate: true })
    if (submitting) submitQuote()
    else pickupAddressModal.off()
  }

  const onShippingAddressChanged = (address: Address) => {
    setValue('shippingAddress', address, { shouldValidate: true })
    shippingAddressModal.off()
  }

  const cancel = () => {
    deletePersistedForm(storageUniqueKey)
    if (onCancel) onCancel()
  }

  const submit = handleSubmit(
    () => {
      if (deliveryMethod === DeliveryMethod.Pickup) {
        submitToggle.on()
        pickupAddressModal.on()
        return
      }

      submitQuote()
    },
    (errors) => {
      // Only scroll to urgency if there are no other errors (since it's at the end of the form)
      if (errors.urgency && Object.keys(errors).length === 1) {
        urgencyRef.current?.scrollIntoView()
      }
    }
  )

  const debouncedSearchCatalog = useRef(debouncePromise(searchCatalog, 500)).current

  const onFetchProducts = useCallback(async (prefix: string): Promise<SearchCatalogResults> => {
    const start = new Date()
    const result = await debouncedSearchCatalog({ variables: { prefix } })

    if (result === 'skipped') {
      return []
    }

    const end = new Date()

    GE.searchesCollection(
      'products',
      prefix,
      end.getTime() - start.getTime(),
      result.data?.autocompleteProducts.length ?? null
    )

    return result.data?.autocompleteProducts ?? []
  }, [])

  const selectProduct = (
    i: number,
    p: SearchCatalogResults[number],
    input: string,
    search: string
  ) => {
    setValue(`items.${i}.partNumber`, p.mpn)
    setValue(`items.${i}.description`, p.name)
    setValue(`items.${i}.unitPrice`, p.salePrice)

    const inStock = p.availability === ProductAvailability.InStock
    setValue(`items.${i}.inStock`, inStock)

    const leadTime = !inStock && p.leadTime ? parseInt(p.leadTime, 10) : null

    let availabilityDate: Maybe<string> = null
    if (leadTime && leadTime > 0) {
      availabilityDate = DateTime.now().plus({ days: leadTime }).toISO()
    }

    setValue(`items.${i}.availabilityDate`, availabilityDate)

    GE.selectsProduct(p.id, input, search)
  }

  const customerDiscountAutoFilled =
    !formState.dirtyFields.customerDiscount && customerDiscount !== null

  return (
    <>
      <AddressModal
        title="Confirm Will Call Address"
        subtitle="Since your customer wants to pick up this order at will call, please confirm your address."
        address={pickupAddress ?? AddressM.init()}
        open={pickupAddressModalOpen}
        onClose={pickupAddressModal.off}
        onConfirm={onPickupAddressConfirmed}
        submitInProgress={saveQuoteInProgress}
      />

      <AddressModal
        title={deliveryMethod === DeliveryMethod.Shipping ? 'Shipping Address' : 'Customer Address'}
        address={shippingAddress ?? AddressM.init()}
        open={shippingAddressModalOpen}
        onClose={shippingAddressModal.off}
        onConfirm={onShippingAddressChanged}
        submitInProgress={saveQuoteInProgress}
      />

      <AddCustomerModal
        open={addCustomerOpen}
        onClose={addCustomerToggle.off}
        onContactSelected={(newContactId) =>
          setValue('customerContactId', newContactId, { shouldValidate: true })
        }
      />

      <form onSubmit={submit} className="flex flex-col sm:flex-row gap-x-6">
        <Box className="shadow-base flex-grow p-6 pt-0 divide-y">
          <Section className="flex flex-col gap-y-4">
            {actions}

            {isDirectQuote && (
              <div>
                <FieldLabel>Select Customer</FieldLabel>

                <Controller
                  control={control}
                  name="customerContactId"
                  render={({ field }) => (
                    <CustomersDropdown
                      value={field.value}
                      onChange={field.onChange}
                      disabled={false}
                    />
                  )}
                />
                <FieldError error={formState.errors.customerContactId?.message} />
                {disabled ? (
                  <SimpleTooltip
                    text="To unlock this feature upgrade to Sales Hub Pro"
                    placement="bottom-start"
                  >
                    <Action.S onClick={addCustomerToggle.on} className="mt-4" disabled={disabled}>
                      <PlusIcon className="inline-block h-5 w-5 text-gray-700 -mt-1" /> Add new
                      customer
                    </Action.S>
                  </SimpleTooltip>
                ) : (
                  <Action.S onClick={addCustomerToggle.on} className="mt-4" disabled={disabled}>
                    <PlusIcon className="inline-block h-5 w-5 text-gray-700 -mt-1" /> Add new
                    customer
                  </Action.S>
                )}
              </div>
            )}

            {itemsForm.fields.map((item, i) => (
              <div key={item.id} className="bg-gray-50 rounded-xl p-4 flex flex-col gap-y-3">
                <div className="flex justify-between">
                  <h4 className="text-lg font-medium">
                    Part {itemsValues.length > 1 ? i + 1 : null}
                  </h4>
                  {canRemoveItems && itemsValues[i] && (
                    <RemovePartButton
                      part={{
                        partNumber: itemsValues[i].partNumber,
                        description: itemsValues[i].description,
                        quantity: itemsValues[i].quantity,
                      }}
                      onRemove={() => itemsForm.remove(i)}
                    />
                  )}
                </div>

                <div className="flex flex-col sm:flex-row items-center gap-x-2 gap-y-3">
                  <Controller
                    control={control}
                    name={`items.${i}.partNumber`}
                    render={({ field }) => (
                      <Field
                        label="Part Number"
                        error={formState.errors?.items?.[i]?.partNumber?.message}
                        className="w-full sm:w-1/3"
                      >
                        <Typeahead
                          inputRef={field.ref}
                          value={field.value}
                          onChange={(mpn) => field.onChange(mpn)}
                          onFetchOptions={onFetchProducts}
                          placeholder="ie. ES3897B"
                          className="w-full"
                          placement="bottom-start"
                          renderOption={(o) => <CatalogAutoCompleteOption product={o} />}
                          transformSelection={(o) => o.mpn}
                          afterSelection={(o) => selectProduct(i, o, 'part_number', field.value)}
                          id={`part-${i}-mpn`}
                          menuWidth={500}
                          menuHeight={600}
                          menuTitle="From your catalog"
                          menuOpenOnClick
                          fetchWithEmptyPrefix
                        />
                      </Field>
                    )}
                  />

                  <div className="w-full sm:w-2/3 flex gap-x-2">
                    <Field
                      label="Quantity"
                      error={formState.errors?.items?.[i]?.quantity?.message}
                      className="w-full sm:w-1/2"
                    >
                      <TextInput
                        type="number"
                        step={1}
                        min={1}
                        {...register(`items.${i}.quantity`, { valueAsNumber: true })}
                        placeholder="0"
                      />
                    </Field>

                    <Controller
                      control={control}
                      name={`items.${i}.unitPrice`}
                      render={({ field, fieldState }) => (
                        <Field
                          label="Unit Price"
                          error={fieldState.error?.message}
                          className="w-full sm:w-1/2"
                        >
                          <PriceV2
                            price={field.value ? MoneyM.fromDecimal(field.value, 'USD') : undefined}
                            onChange={(value) =>
                              field.onChange(value ? MoneyM.toDecimal(value) : null)
                            }
                            ref={field.ref}
                          />
                        </Field>
                      )}
                    />
                  </div>
                </div>

                <Controller
                  control={control}
                  name={`items.${i}.description`}
                  render={({ field }) => (
                    <Field
                      label="Part Description"
                      error={formState.errors?.items?.[i]?.description?.message}
                    >
                      <Typeahead
                        inputRef={field.ref}
                        value={field.value}
                        onChange={(desc) => field.onChange(desc)}
                        onFetchOptions={onFetchProducts}
                        placeholder="ie. Air Filter, Fuel Pump"
                        className="w-full"
                        placement="bottom-start"
                        renderOption={(o) => <CatalogAutoCompleteOption product={o} />}
                        transformSelection={(o) => o.name}
                        afterSelection={(o) => selectProduct(i, o, 'description', field.value)}
                        id={`part-${i}-desc`}
                        menuTitle="From your catalog"
                        menuOpenOnClick
                        fetchWithEmptyPrefix
                        menuHeight={600}
                      />
                    </Field>
                  )}
                />

                <Controller
                  control={control}
                  name={`items.${i}.inStock`}
                  render={({ field }) => (
                    <div className="py-1">
                      <InStockCheckbox
                        checked={field.value}
                        onChange={field.onChange}
                        deliveryMethod={deliveryMethod}
                      />
                    </div>
                  )}
                />

                <Controller
                  name={`items.${i}.availabilityDate`}
                  control={control}
                  render={({ field }) =>
                    !itemsValues?.[i]?.inStock ? (
                      <Field
                        label="Select date"
                        error={formState.errors?.items?.[i]?.availabilityDate?.message}
                      >
                        <DateInput
                          value={field.value ? TimeM.fromPayload(field.value) : undefined}
                          min={TimeM.toDateInputFormat(DateTime.now())}
                          ref={field.ref}
                          onChange={(value) => {
                            field.onChange(value ? TimeM.toPayload(value) : null)
                          }}
                        />
                      </Field>
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
            ))}

            <div>
              <Action.S onClick={() => itemsForm.append(newLineItem())}>
                <PlusIcon className="inline-block h-5 w-5 text-gray-700 -mt-1" /> Add another part
              </Action.S>
            </div>
          </Section>

          {showDeliveryMethod && (
            <Section
              className={classNames('space-y-4', deliveryMethodAppearsDisabled && 'opacity-50')}
            >
              <Controller
                control={control}
                name="deliveryMethod"
                render={({ field, fieldState }) => (
                  <div>
                    <FieldLabel>Delivery Method</FieldLabel>
                    <DeliveryMethodSelector
                      value={field.value}
                      onChange={(newValue) => {
                        if (newValue !== DeliveryMethod.Shipping) setValue('shippingCost', null)
                        field.onChange(newValue)
                      }}
                    />
                    <FieldError error={fieldState.error?.message} />
                  </div>
                )}
              />

              {deliveryMethod && (
                <div className="grid grid-cols-2 gap-x-6">
                  <div className="space-y-2">
                    <AddressC
                      address={shippingAddress}
                      onChangeClicked={shippingAddressModal.on}
                      title={
                        deliveryMethod === DeliveryMethod.Shipping
                          ? 'Shipping Address'
                          : 'Customer Address'
                      }
                      textSize="md"
                      titleSize="md"
                    />

                    <FieldError error={formState.errors.shippingAddress?.message} />
                  </div>

                  {deliveryMethod === DeliveryMethod.Pickup && (
                    <div className="space-y-2">
                      <AddressC
                        address={pickupAddress}
                        onChangeClicked={pickupAddressModal.on}
                        title="Will Call Location"
                        textSize="md"
                        titleSize="md"
                      />

                      <FieldError error={formState.errors.pickupAddress?.message} />
                    </div>
                  )}
                </div>
              )}
            </Section>
          )}

          {isDirectQuote && (
            <div className={classNames('divide-y', !customerContactId && 'opacity-50')}>
              <Section className="space-y-4">
                <Controller
                  control={control}
                  name="urgency"
                  render={({ field, fieldState }) => (
                    <div ref={urgencyRef}>
                      <FieldLabel>Urgency</FieldLabel>
                      <p className="text-gray-600 text-sm pb-3">
                        When does your customer need their part?
                      </p>
                      <UrgencySelector
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value)
                          setValue('neededByDate', neededByDateFromUrgency(value), {
                            shouldValidate: true,
                          })
                        }}
                      />
                      <FieldError error={fieldState.error?.message} />
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="neededByDate"
                  render={({ field, fieldState }) => (
                    <Field label="Select Date" error={fieldState.error?.message}>
                      <DateInput
                        ref={field.ref}
                        value={field.value ?? undefined}
                        onChange={field.onChange}
                        className="max-w-44"
                      />
                    </Field>
                  )}
                />
              </Section>

              <Section>
                <FieldLabel>Comments (optional)</FieldLabel>

                <p className="text-gray-600 text-sm">
                  Add comments that would help customer approve the quote i.e. additional part
                  required, delay on fulfilling a part
                </p>

                <TextArea {...register('comments')} className="w-full mt-3" />
              </Section>
            </div>
          )}

          {machineDetailsSection && <Section>{machineDetailsSection}</Section>}

          {images.length > 0 && (
            <Section>
              <ImageGallery images={images} className="px-4" />
            </Section>
          )}
        </Box>

        {/* SIDEBAR */}
        <div className="relative">
          <div className="w-80 flex flex-col gap-y-4 sticky top-4 lg:top-16">
            {sideNote}

            {secondaryActions}

            {deliveryMethod === DeliveryMethod.Shipping && (
              <Box className="shadow-base p-6 space-y-4">
                {shippingAddress && <AddressC address={shippingAddress} title="Shipping Address" />}

                <Controller
                  name="shippingCost"
                  control={control}
                  render={({ field }) => (
                    <Field label="Shipping cost" error={formState.errors?.shippingCost?.message}>
                      <PriceV2
                        ref={field.ref}
                        price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                        onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                      />
                    </Field>
                  )}
                />
              </Box>
            )}

            <Box className="shadow-base p-6 space-y-4">
              {showSalesTax && (
                <Controller
                  name="taxCost"
                  control={control}
                  render={({ field }) => (
                    <Field label="Sales tax (optional)">
                      <PriceV2
                        ref={field.ref}
                        price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                        onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                      />
                    </Field>
                  )}
                />
              )}

              <Controller
                name="customerDiscount"
                control={control}
                render={({ field }) => (
                  <div className="space-y-2">
                    <div className="flex gap-2 items-center justify-between">
                      <label className="text-sm text-gray-900 block" htmlFor="customer-discount">
                        Customer discount
                      </label>

                      {showWhenCustomerDiscountAutoFilled && customerDiscountAutoFilled && (
                        <div className="-my-1">
                          <BadgeNext color="yellow">Auto-filled</BadgeNext>
                        </div>
                      )}
                    </div>

                    <Percent
                      value={field.value}
                      setValue={field.onChange}
                      ref={field.ref}
                      max={100}
                      id="customer-discount"
                    />
                  </div>
                )}
              />

              <Field label="Quote number (optional)">
                <TextInput {...register('quoteNumber')} />
              </Field>

              <Summary
                total={totals.total}
                subtotal={totals.subtotal}
                discount={totals.discount}
                discountPercent={customerDiscount}
                shippingCost={shippingAmount}
                taxCost={!customerFeeRate || !MoneyM.isZero(taxAmount) ? taxAmount : null}
                customerFee={totals.customerFee}
                refunded={MoneyM.fromInt(0, 'USD')}
                showShippingCost={deliveryMethod === DeliveryMethod.Shipping}
              />

              <div className="flex gap-x-4">
                {onCancel && (
                  <Action.S onClick={cancel} className="w-1/3">
                    Cancel
                  </Action.S>
                )}
                <Action.P
                  type="submit"
                  disabled={saveQuoteInProgress}
                  performing={saveQuoteInProgress}
                  className={onCancel ? 'w-2/3' : 'w-full'}
                >
                  Submit Quote
                </Action.P>
              </div>
            </Box>
          </div>
        </div>
      </form>
    </>
  )
}

export default QuoteBuilder
